export default function VoteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 5.03571C4 3.91183 4.89583 3 6 3H18C19.1042 3 20 3.91183 20 5.03571V17.9286H4V5.03571ZM17.0417 7.02902C16.65 6.63036 16.0167 6.63036 15.6292 7.02902L11.0042 11.7366L9.04583 9.7433C8.65417 9.34464 8.02083 9.34464 7.63333 9.7433C7.24583 10.142 7.24167 10.7866 7.63333 11.181L10.3 13.8953C10.6917 14.294 11.325 14.294 11.7125 13.8953L17.0417 8.47098C17.4333 8.07232 17.4333 7.42768 17.0417 7.03326V7.02902ZM0 15.8929C0 14.769 0.895833 13.8571 2 13.8571H2.66667V19.2857H21.3333V13.8571H22C23.1042 13.8571 24 14.769 24 15.8929V19.9643C24 21.0882 23.1042 22 22 22H2C0.895833 22 0 21.0882 0 19.9643V15.8929Z"
        fill="currentColor"
      />
    </svg>
  )
}
