export default function HomeIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Home">
        <path
          id="Vector"
          d="M23.4942 12.4814C23.4942 13.1494 22.9213 13.6727 22.2719 13.6727H21.0496L21.0763 19.6176C21.0763 19.7178 21.0687 19.818 21.0572 19.9182V20.5156C21.0572 21.3357 20.3735 22 19.5293 22H18.9181C18.8761 22 18.8341 22 18.7921 21.9963C18.7386 22 18.6851 22 18.6317 22H17.3902H16.4735C15.6293 22 14.9456 21.3357 14.9456 20.5156V19.625V17.25C14.9456 16.5932 14.3994 16.0625 13.7233 16.0625H11.2786C10.6025 16.0625 10.0563 16.5932 10.0563 17.25V19.625V20.5156C10.0563 21.3357 9.37255 22 8.52838 22H7.61163H6.39313C6.33583 22 6.27853 21.9963 6.22124 21.9926C6.1754 21.9963 6.12956 22 6.08372 22H5.47256C4.62839 22 3.94465 21.3357 3.94465 20.5156V16.3594C3.94465 16.326 3.94465 16.2889 3.94847 16.2555V13.6727H2.72233C2.03477 13.6727 1.5 13.1531 1.5 12.4814C1.5 12.1475 1.61459 11.8506 1.88198 11.5908L11.6759 3.29688C11.9432 3.03711 12.2488 3 12.5162 3C12.7836 3 13.0892 3.07422 13.3184 3.25977L23.0741 11.5908C23.3796 11.8506 23.5324 12.1475 23.4942 12.4814Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
