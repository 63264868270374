export default function SearchIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Search">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.02001 6.71998C6.02001 5.44694 6.52573 4.22605 7.4259 3.32587C8.32608 2.4257 9.54697 1.91998 10.82 1.91998C12.0931 1.91998 13.314 2.4257 14.2141 3.32587C15.1143 4.22605 15.62 5.44694 15.62 6.71998C15.62 7.99302 15.1143 9.21392 14.2141 10.1141C13.314 11.0143 12.0931 11.52 10.82 11.52C9.54697 11.52 8.32608 11.0143 7.4259 10.1141C6.52573 9.21392 6.02001 7.99302 6.02001 6.71998ZM2.42001 20.0062C2.42001 16.3125 5.41251 13.32 9.10626 13.32H12.2543C12.102 13.813 12.02 14.3369 12.02 14.88C12.02 17.796 14.384 20.16 17.3 20.16C17.9762 20.16 18.6227 20.0329 19.2169 19.8012C19.219 19.8693 19.22 19.9377 19.22 20.0062C19.22 20.6212 18.7213 21.12 18.1063 21.12H3.53376C2.91876 21.12 2.42001 20.6212 2.42001 20.0062ZM21.5605 14.8495C21.5605 15.796 21.2532 16.6704 20.7355 17.3798L23.3467 19.9926C23.6045 20.2504 23.6045 20.669 23.3467 20.9268C23.0889 21.1846 22.6702 21.1846 22.4123 20.9268L19.8011 18.314C19.0916 18.8337 18.217 19.1389 17.2703 19.1389C14.9004 19.1389 12.9801 17.219 12.9801 14.8495C12.9801 12.48 14.9004 10.5601 17.2703 10.5601C19.6402 10.5601 21.5605 12.48 21.5605 14.8495ZM17.2635 12.3785C15.8916 12.3785 14.7794 13.4907 14.7794 14.8626C14.7794 16.2346 15.8916 17.3467 17.2635 17.3467C18.6355 17.3467 19.7477 16.2346 19.7477 14.8626C19.7477 13.4907 18.6355 12.3785 17.2635 12.3785Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
